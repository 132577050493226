<template>
    <div class="mt-2 overflow-x-auto shadow-md rounded-md bg-gradient-to-b from-bo-table to-bo-light-html">
        <table class="flex flex-col justify-center items-center w-full mx-auto text-gray-800 border-separate" style="min-width: 60rem">
            <i v-if="loading" class="mdi mdi-autorenew mdi-spin text-6xl absolute m-0 p-0 text-blue-cust opacity-50"></i>
            <thead class="w-full flex flex-row border-b border-gray-500 text-gray-400">
            <tr class="w-full flex flex-row" :class="{'custom-width-header' : isFirefox && data && data.length > visibleRowsQtyInTable}">
                <th v-for="(field) in fields" :key="field.key" @click="clickOnField(field.key)"
                    :style="field.key === 'course_name' || field.key === 'com_progress_percent' ? 'width: '+colWidthLarge+'%;' : 'width: '+colWidth+'%;'"
                >
                    <CellHeader :column="field.key" :fieldBy="fieldBy" :direction="direction" :fieldLabel="field.label"/>
                </th>
            </tr>
            </thead>
            <tbody class="w-full flex flex-col overflow-y-auto h-bo-mobile-tbody md:h-bo-ipad-tbody xl:h-bo-desktop-tbody">
            <tr v-if="data && !data.length" class="flex h-full"><td class="w-full flex flex-row justify-center items-center text-3xl">{{emptyResultsMsg}}</td></tr>
            <tr @click="clickOnRow(fullRow, rowIndex)" v-for="(fullRow,rowIndex) in data" :key="`row-${rowIndex}`" class="w-full flex flex-row cursor-pointer"
                :class="{
                    'bo-even': rowIndex % 2 !== 0,
                    'bo-odd': rowIndex % 2 === 0,
                    'row-selected': rowIndex === rowIndexSelected
                }">
                <td v-for="(field) in fields" :key="`col-${field.key}`"
                    :style="field.key === 'course_name' || field.key === 'com_progress_percent' ? 'width: '+colWidthLarge+'%;' : 'width: '+colWidth+'%;'"
                >
                    <slot
                        :name="field.key"
                        :fieldIndex="field.key"
                        :fullRow="fullRow"
                        :value="fullRow[field.key]"
                        :fieldLabel="field.label"
                    >
                        <p v-if="fullRow[field.key]" class="py-4 px-2 truncate">{{ fullRow[field.key] }}</p>
                    </slot>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { actions } from "@/store";
import CellHeader from "@/pages/inside/shared/datatables/cells/CellHeader";

export default {
    name: "Table",
    props: ['data', 'fields', 'fieldBy', 'direction', 'loading'],
    components: { CellHeader },
    data() {
        return {
            rowIndexSelected: null
        }
    },
    watch: {
        elSelected(n) {
            if (!n){
                this.rowIndexSelected = null;
            }
        }
    },
    computed: {
        elSelected() {
            return actions.getElSelected();
        },
        visibleRowsQtyInTable() {
            return actions.getVisibleRowsQtyInTable();
        },
        colWidth() {
            return 60 / (Object.keys(this.fields).length - 2);
        },
        colWidthLarge() {
            return 20;
        }
    },
    methods: {
        clickOnRow(el, index) {
            if (index === this.rowIndexSelected) {
                actions.setElSelected(null);
                this.rowIndexSelected = null;
            } else {
                actions.setElSelected(el);
                this.rowIndexSelected = index;
            }
        },
        clickOnField(fieldBy) {
            actions.setElSelected(null);
            if (fieldBy === this.fieldBy){
                if (this.direction === 'asc') {
                    this.$emit('changeDirection', 'desc');
                } else {
                    this.$emit('changeDirection', 'asc');
                }
            } else {
                this.$emit('changeField', fieldBy);
            }
        }
    },
}
</script>

<style lang="scss" scoped>
</style>