<template>
    <All model="Edition" :immediate="true" ref="editionsRef" v-slot="{data:editions}" :query="editionsFilter" @loading:change="(event) => loading = event">
        <SectionWrapper>
            <div class="w-full px-1 sm:px-2 xl:px-5">
                <Header :title="title"/>
                <div class="py-2 w-full flex flex-row items-between justify-center">
                    <perPageSelector @changeOption="changePerPage"/>
                    <div class="w-auto sm:w-1/3 flex flex-row flex-grow justify-center sm:justify-center sm:px-6">
                        <new-mod :entity="entity" @refreshDataTable="refreshDataTable"/>
                        <show-mod v-if="elSelected" :entity="entity" @refreshDataTable="refreshDataTable"/>
                        <destroy-mod v-if="elSelected" :entity="entity" @refreshDataTable="refreshDataTable"/>
                    </div>
                    <searcher @changeText="changeText"/>
                </div>
                <Table
                    :data="editions.data" :fields="fields" :fieldBy="editionsFilter.field"
                    :direction="editionsFilter.direction" :loading="loading"
                    @changeField="changeField" @changeDirection="changeDirection">

                    <template v-slot:customers_size="{ fullRow }">
                        <p v-if="fullRow.customers_target && fullRow.customers_size" class="py-4 px-2 truncate">{{ fullRow.customers_size }}</p>
                        <p v-else-if="fullRow.customers_target && !fullRow.customers_size" class="py-4 px-2 truncate">0</p>
                        <p v-else></p>
                    </template>

                    <template v-slot:customers_target_percent_reached="{ fullRow }">
                        <CellTargetReached :fullRow="fullRow"></CellTargetReached>
                    </template>

                    <template v-slot:opportunities_plus_size="{ fullRow }">
                        <p v-if="fullRow.opportunities_plus_target && fullRow.opportunities_plus_size" class="py-4 px-2 truncate">{{ fullRow.opportunities_plus_size }}</p>
                        <p v-else-if="fullRow.opportunities_plus_target && !fullRow.opportunities_plus_size" class="py-4 px-2 truncate">0</p>
                        <p v-else></p>
                    </template>

                    <template v-slot:opportunities_plus_target_percent_reached="{ fullRow }">
                        <div class="flex h-full justify-center items-center">
                            <div v-if="fullRow.area_has_opportunities_plus && fullRow.opportunities_plus_target_percent_reached"
                                 class="p-1 rounded-full w-14 h-14 border-4 flex justify-center items-center"
                                 :class="{
                                    'border-opacity-100': fullRow.com_remaining_days > 30,
                                    'border-bad': fullRow.com_light === 'bad',
                                    'border-regular': fullRow.com_light === 'regular',
                                    'border-good': fullRow.com_light === 'good'
                                 }">
                                {{ fullRow.opportunities_plus_target_percent_reached | numberFormat(0) }}%
                            </div>
                            <div v-else-if="fullRow.area_has_opportunities_plus && fullRow.opportunities_plus_size"
                                 class="p-1 rounded-full w-14 h-14 border-4 bg-bad flex justify-center items-center">
                                {{ 0 | numberFormat(0) }}%
                            </div>
                        </div>
                    </template>

                    <template v-slot:current_opportunities_plus_weight="{ fullRow }">
                        <p v-if="fullRow.opportunities_plus_target" class="py-4 px-2 truncate">{{ fullRow.current_opportunities_plus_weight }}</p>
                        <p v-else class="py-4 px-2 truncate"></p>
                    </template>

                    <template v-slot:com_progress_percent="{ fullRow }">
                        <CellComProgressInfo v-if="fullRow.com_progress_percent || fullRow.com_progress_percent === 0" :fullRow="fullRow"/>
                    </template>

                </Table>
                <paginate v-if=editions.meta :meta=editions.meta @changePage="changePage"/>
                <PaginateTextInfo v-if="editions.meta" :meta="editions.meta" class="flex-auto text-center sm:hidden"/>
                <PaginateMobile :links="editions.links" @changePage="changePage" class="sm:hidden"/>
            </div>
        </SectionWrapper>
    </All>
</template>

<script>
import {All} from '@/api/components';
import PerPageSelector from "@/pages/inside/shared/datatables/PerPageSelector";
import Searcher from "@/pages/inside/shared/datatables/Searcher";
import Table from "@/pages/inside/sections/editions/Table";
import Paginate from "@/pages/inside/shared/datatables/Paginate";
import PaginateMobile from "@/pages/inside/shared/datatables/PaginateMobile";
import ShowMod from "@/pages/inside/sections/editions/modals/Show";
import NewMod from "@/pages/inside/sections/editions/modals/New";
import DestroyMod from "@/pages/inside/sections/editions/modals/Destroy";
import {actions, state} from "@/store";
import Header from "@/pages/inside/shared/datatables/Header";
import SectionWrapper from "@/pages/inside/shared/slots/SectionWrapper";
import PaginateTextInfo from "@/pages/inside/shared/datatables/PaginateTextInfo";
import CellComProgressInfo from "@/pages/inside/shared/datatables/cells/CellComProgressInfo";
import CellTargetReached from "@/pages/inside/shared/datatables/cells/CellCustomersTargetReached";
export default {
    name: "Editions",
    components:{
        All, SectionWrapper, Header, Paginate, PaginateMobile, PerPageSelector, Searcher, Table, ShowMod, NewMod, DestroyMod, PaginateTextInfo, CellComProgressInfo, CellTargetReached
    },
    data() {
        return {
            title: 'Ediciones',
            entity: 'Edición',
            loading: false,
            editionsFilter: {
                page: null,
                perPage: state.visibleRowsQtyInTable,
                field: 'name',
                direction: 'asc',
                text: null
            },
            fields,
        }
    },
    computed: {
        elSelected() {
            return actions.getElSelected();
        },
    },
    mounted() {
        actions.setElSelected(false);
    },
    methods: {
        refreshDataTable(keepSelection = false) {
            if (!keepSelection) {
                actions.setElSelected(false);
            }
            this.$refs.editionsRef.request();
        },

        changePage(page){
            this.editionsFilter.page = page;
            this.refreshDataTable();
        },

        changePerPage(option){
            this.editionsFilter.perPage = option;
            this.editionsFilter.page = 1;
            this.refreshDataTable();
        },

        changeField(field){
            this.editionsFilter.field = field;
            this.refreshDataTable();
        },

        changeDirection(direction){
            this.editionsFilter.direction = direction;
            this.refreshDataTable();
        },

        changeText(text){
            this.editionsFilter.page = null;
            this.editionsFilter.text = text;
            this.refreshDataTable();
        },
    }
};

const fields = [
    {
        label: 'Área',
        key: 'area_name'
    },
    {
        label: 'Curso',
        key: 'course_name'
    },
    {
        label: 'Edición',
        key: 'name'
    },
    {
        label: 'Clientes',
        key: 'customers_size'
    },
    {
        label: 'Objetivo Clientes',
        key: 'customers_target'
    },
    {
        label: 'Clientes Ratio',
        key: 'customers_target_percent_reached'
    },
    {
        label: 'Oportunidades Plus',
        key: 'opportunities_plus_size'
    },
    {
        label: 'Objetivo Oportunidades Plus',
        key: 'opportunities_plus_target'
    },
    {
        label: 'Oportunidades Plus Ratio',
        key: 'opportunities_plus_target_percent_reached'
    },
    {
        label: 'Progreso Comercialización',
        key: 'com_progress_percent'
    },
];
</script>

<style scoped>

</style>